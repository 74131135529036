<template functional>
  <div
    :key="data.key"
    :class="[
      'doc-preview-wrapper',
      { 'doc-preview-wrapper--active': props.active },
      { 'doc-preview-wrapper--minimal': props.minimal },
    ]"
  >
    <icon v-if="props.active">check</icon>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'DocPreviewWrapper',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$padding: 2px
$minimal-padding: 1px
$border-width: 2px
$icon-border-width: 1px
$icon-size: 16px

.doc-preview-wrapper
  position: relative

  padding: $padding

  border-radius: $doc-preview-border-radius
  border-style: solid
  border-width: $border-width

  & > .e-icon
    --size: #{$icon-size}

    padding: $icon-size * .25

    border-radius: $b-radius-circle
    border-style: solid
    border-width: $icon-border-width

    +p-absolute-top-right(#{$z-index-base + 1}, $padding * 3)

// - minimal
.doc-preview-wrapper--minimal
  padding: $minimal-padding

  & > .e-icon
    +top-right($minimal-padding * 3)

// color
.doc-preview-wrapper
  border-color: $c-transparent

  & > .e-icon
    --color: var(--c-white)

    background-color: var(--c-primary)
    border-color: var(--c-white)

  +on-active
    border-color: var(--c-primary)
</style>
