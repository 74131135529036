<template>
  <EImage
    v-if="service.type === 'camera'"
    :src="thumbnail"
    v-bind="$attrs"
    class="service-thumbnail"
    v-on="$listeners"
  />

  <Dummy v-else class="d-flex-r d-flex-center">
    <icon :size="42" color="white">stats-bars</icon>
  </Dummy>
</template>

<script>
import { DEBOUNCE_WAIT, DEFAULT_THUMBNAIL } from './constants';
import { fetchFile } from '@/helpers';
import { buildThumbnailFile, getItem, setItem } from './helpers';

// components
import Dummy from '@/components/utils/Dummy.vue';
import EImage from '@/components/ds/Image.vue';

export default {
  name: 'ServiceThumbnail',
  components: { Dummy, EImage },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbnail: DEFAULT_THUMBNAIL,

      debouncedFetchFromRemote_: _.debounce(
        this.fetchFromRemote,
        DEBOUNCE_WAIT,
        { leading: true }
      ),
    };
  },
  computed: {
    lastTimestamp() {
      const { timestamp } = this.service.last;
      if (!timestamp) return;

      return this.$time.format.asDatetime(timestamp);
    },
  },
  watch: {
    lastTimestamp: { handler: 'onLastTimestampChange', immediate: true },
  },
  methods: {
    async fetchFromCache() {
      const thumbnail = await getItem(this.service.id);
      if (!thumbnail) return;

      this.thumbnail = thumbnail;
    },
    async fetchFromRemote() {
      const response = await fetchFile(buildThumbnailFile(this.service));
      if (!response && !response.uri) return;
      const thumbnail = response.uri;

      this.thumbnail = thumbnail;

      setItem(this.service.id, thumbnail);
    },

    onLastTimestampChange(current, previous) {
      const isInitialLastTimestamp = !previous;

      if (this.service.type === 'camera') {
        // in this case, "timestamp-change" will be emitted once the fetched
        // thumbnail has been loaded

        if (isInitialLastTimestamp) this.fetchFromCache();
        if (current && current !== previous) this.debouncedFetchFromRemote_();
      } else this.$emit('complete');
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
