<template>
  <div class="services-thumbnails">
    <div class="services-thumbnails__grid">
      <template v-for="service in services">
        <ServiceThumbnail
          v-show="servicesToShowIds.includes(service.id)"
          :key="service.id"
          :service="service"
          :style="style(service.id)"
          v-bind="$attrs"
          @complete="onComplete(service)"
          v-on="$listeners"
        />
      </template>
    </div>

    <Dummy v-if="state.fillingUp" />
  </div>
</template>

<script>
import { DEBOUNCE_WAIT, DISPOSITIONS } from './constants';

// components
import Dummy from '@/components/utils/Dummy.vue';
import ServiceThumbnail from '@/components/collection/services/ServiceThumbnail/index.vue';

export default {
  name: 'ServicesThumbnails',
  components: { Dummy, ServiceThumbnail },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      servicesToShowIds: [],

      cameraServicesIds: [],
      notCameraServicesIds: [],

      debouncedShowService: _.debounce(this.showService, DEBOUNCE_WAIT),
    };
  },
  computed: {
    state() {
      return {
        fillingUp:
          this.servicesToShowIds.length <
          Math.min(this.services.length, DISPOSITIONS.length),
        idle:
          this.cameraServicesIds.length + this.notCameraServicesIds.length ===
          this.services.length,
      };
    },
  },
  methods: {
    showService(service) {
      if (this.servicesToShowIds.includes(service.id)) return;

      if (this.servicesToShowIds.length > DISPOSITIONS.length - 1)
        this.servicesToShowIds.pop();
      this.servicesToShowIds.unshift(service.id);
    },

    onComplete(service) {
      const { id: serviceId, type } = service;

      if (type === 'camera') {
        if (this.state.fillingUp) this.showService(service);
        if (this.state.idle) this.debouncedShowService(service);

        if (!this.cameraServicesIds.includes(serviceId))
          this.cameraServicesIds.push(serviceId);
      } else {
        if (!this.notCameraServicesIds.includes(serviceId))
          this.notCameraServicesIds.push(serviceId);

        if (this.state.fillingUp && this.state.idle) {
          for (const serviceId of this.notCameraServicesIds) {
            this.servicesToShowIds.push(serviceId);

            if (!this.state.fillingUp) return;
          }
        }
      }
    },

    style(serviceId) {
      const { length } = this.servicesToShowIds;
      if (length === 0) return;

      const index = _.indexOf(this.servicesToShowIds, serviceId);
      if (index === -1) return;

      return { 'grid-area': DISPOSITIONS[length - 1][index] };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$gap: 2px

.services-thumbnails__grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: repeat(2, 1fr)
  gap: $gap

  +size(100%)
</style>
