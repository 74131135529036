<template>
  <div :class="className">
    <div class="timestamp-offset__status mr-2" />

    <t :color="color" xs uppercase>{{ offsetAsString }}</t>
  </div>
</template>

<script>
import { STATUS } from '@/API/preprocessor/constants.ts';

export default {
  name: 'TimestampOffset',
  props: {
    status: {
      type: String,
      default: undefined,
    },
    timestamp: {
      type: Object,
      required: true,
    },

    color: {
      type: String,
      default: 'gray-2',
    },
  },
  data() {
    return {
      offset: null,

      interval: null,
    };
  },
  computed: {
    offsetAsString() {
      return this.$time.offsetAsString(this.offset);
    },
    status_() {
      return this.status || this.$time.offsetToStatus(this.offset);
    },

    className() {
      const className = 'timestamp-offset';

      return [className, `${className}--${this.status_}`];
    },
  },
  watch: {
    timestamp() {
      this.teardown();
      this.setup();
    },
  },
  created() {
    this.setup();
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    updateOffset() {
      this.offset = this.$time.timestampToOffset(this.timestamp);
    },

    setup() {
      this.offset = this.$time.timestampToOffset(this.timestamp);

      // is useless to animate the passing of time to a not active status
      if (this.status_ !== STATUS.active) return;

      this.interval = setInterval(this.updateOffset, 1000);
    },
    teardown() {
      clearInterval(this.interval);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$status-size: .75rem

.timestamp-offset
  +d-flex-r(center, center, inline)

.timestamp-offset__status
  flex-shrink: 0

  +circle($status-size)

.timestamp-offset > .e-text
  font-size: .625rem

.timestamp-offset
  &--active > .timestamp-offset__status
    animation: twinkle 2s infinite

// color
.timestamp-offset
  &--active > .timestamp-offset__status
    background-color: var(--c-green)

  &--inactive > .timestamp-offset__status
    background-color: var(--c-gray-2)

@keyframes twinkle
  0%
    opacity: .25

  25%
    opacity: 1

    animation-timing-function: $ease-out-quad

  50%
    opacity: 1

  100%
    opacity: .25

    animation-timing-function: $ease-in-quad
</style>
