import localforage from 'localforage';

// fetching from cache...
const localForageService = localforage.createInstance({
  name: 'thumbnails',
  storeName: 'thumbnails-store',
  description: "localForage cache for the service's thumbnails",
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
});

export const getItem = key => localForageService.getItem(key).catch(() => null);
export const setItem = (key, value) => localForageService.setItem(key, value);

// fetching from remote...
export const buildThumbnailFile = service => ({
  id: service.id,
  bucket: service.last.thumbnail.bucket,
  filename: service.last.thumbnail.filename,
});
