<template>
  <div :class="className" @click="onClick">
    <div class="doc-preview__background">
      <ServicesThumbnails v-if="ready" :services="services" />
    </div>

    <div class="doc-preview__body">
      <t v-if="doc.name" color="white" semibold>{{ doc.name }}</t>

      <div v-if="ready" class="d-flex-r d-flex-a-c d-flex-j-sb fluid">
        <TimestampOffset
          v-if="timestamp"
          :status="doc.status"
          :timestamp="timestamp"
          color="white"
          :class="{ 'mt-1': doc.name }"
        />

        <div
          v-if="services.length > 1"
          class="doc-preview__count d-flex-r h-spacing-2"
        >
          <icon color="white" :size="16">video</icon>
          <t color="white" xs>{{ services.length }}</t>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// components
import ServicesThumbnails from '@/components/collection/services/ServicesThumbnails/index.vue';
import TimestampOffset from '@/components/ds/TimestampOffset.vue';

export default {
  name: 'DocPreview',
  components: { ServicesThumbnails, TimestampOffset },
  props: {
    doc: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },

    minimal: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('collections', [
      'getServicesByIds',

      'isProjectFetchedById',
      'isServiceFetchedById',
    ]),

    services() {
      let servicesIds = [];

      if (this.type === 'projects') servicesIds = this.doc.servicesIds;
      if (this.type === 'services') servicesIds = [this.doc.id];

      return this.getServicesByIds(servicesIds);
    },

    timestamp() {
      if (this.services.length === 0) return null;

      return moment.max(_.compact(_.map(this.services, 'last.timestamp')));
    },

    ready() {
      if (this.type === 'projects')
        return this.isProjectFetchedById(this.doc.id, true);
      if (this.type === 'services')
        return this.isServiceFetchedById(this.doc.id);

      return false;
    },

    className() {
      const className = 'doc-preview';

      return [
        className,
        {
          [`${className}--minimal`]: this.minimal,
          [`${className}--sm`]: this.sm,
          [`${className}--square`]: this.square,
        },
      ];
    },
  },
  methods: {
    onClick() {
      let route;

      if (this.type === 'projects')
        route = {
          name: 'projects-show',
          params: { projectId: this.doc.id },
        };
      if (this.type === 'services')
        route = {
          name: 'projects-show-services-show',
          params: { projectId: this.doc.projectId, serviceId: this.doc.id },
        };

      this.$router.push(route).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
$transition-duration: $duration-quickly

.doc-preview
  position: relative

  min-width: $doc-preview-min-width

  border-radius: $doc-preview-border-radius
  box-shadow: $elevation-5
  overflow: hidden

  transition: box-shadow
  transition-duration: $transition-duration

  +button

  +on-hover
    box-shadow: $elevation-5

.doc-preview__background
  +aspect-ratio

.doc-preview__body
  padding: $spacing-2 $spacing-3

  transition-duration: $transition-duration
  transition: background-color

  +d-flex-c(flex-start)
  +p-absolute($z-index-base)
  +shell-bottom(0)

  & > .e-text
    --f-size: #{$f-size-sm}

// - minimal
.doc-preview--minimal
  .doc-preview__body
    padding: $spacing-1

    & > .e-text
      --f-size: #{$f-size-sm}
      --f-weight: #{$f-weight-medium}

    .doc-preview__count, .timestamp-offset
      display: none

// - sm
.doc-preview--sm
  min-width: $doc-preview-sm-min-width

  .doc-preview__body
    font-size: $f-size-xs

    & > .e-text
      --f-size: 1em

    .timestamp-offset > .e-text
      --f-size: .84em

// - square
.doc-preview--square
  .doc-preview__background
    +aspect-ratio(1, 1)

// color
.doc-preview
  .doc-preview__body
    background-color: rgba(17, 17, 17, .4) // FIX

  +on-hover
    .doc-preview__body
      background-color: rgba(17,17,17,0.6) // FIX
</style>
