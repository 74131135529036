<template>
  <div class="e-image">
    <img
      v-for="(source, index) in sources"
      v-show="currentIndex === index"
      :key="`source-${index}`"
      ref="source"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'EImage',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sources: [this.src, this.src],
      currentIndex: 0,
    };
  },
  computed: {
    nextIndex() {
      // returns the next source index in a circular manner

      return (this.currentIndex + 1) % 2;
    },
  },
  watch: {
    src(current, previous) {
      if (current === previous) return;

      this.setNextSource(current);
    },
  },
  mounted() {
    this.$refs.source.forEach(el =>
      el.addEventListener('load', this.onComplete)
    );

    [...this.sources.keys()].forEach(this.startSRCLoad);
  },
  beforeDestroy() {
    this.$refs.source.forEach(el =>
      el.removeEventListener('load', this.onComplete)
    );
  },
  methods: {
    startSRCLoad(index) {
      // sets the image HTML element <src> attribute with its corresponding
      // source

      this.$refs.source[index].src = this.sources[index];
    },
    setNextSource(source) {
      // sets the next source (not displayed yet)

      this.sources[this.nextIndex] = source;
      this.startSRCLoad(this.nextIndex);
    },
    shift() {
      // sets the currently displayed source

      this.currentIndex = this.nextIndex;
    },
    onComplete() {
      this.shift();

      this.$emit('complete');
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.e-image
  +size(100%)

  & > img
    display: block

    object-fit: cover

    +size(100%)
</style>
